@import '../../../../theme/palette';

.JobDraftCategory__slider {
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  margin-top: 20px;
}

.JobDraftCategory__slider .ant-slider {
  margin-top: 29px;
}

.JobDraftCategory__slider .ant-slider-rail {
  height: 6px; /* Adjust the height as needed */
  border-radius: 6px;
}

.JobDraftCategory__slider .ant-slider-track {
  height: 10px; /* Adjust the height as needed */
  background-color: transparent;
}

.JobDraftCategory__slider .ant-slider-dot {
  display: none;
}

.JobDraftCategory__slider .ant-slider-handle {
  width: 14px;
  height: 14px;
  border: none;
  filter: drop-shadow(0px 3px 10px rgba(104, 106, 194, 0.4)) drop-shadow(0px 2px 5px rgba(104, 106, 194, 0.3));
}

/* Custom mark styling */
.JobDraftCategory__slider .custom-mark-start {
  margin-left: 45px;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #9393b0;
}

.JobDraftCategory__slider .custom-mark-end {
  margin-top: 5px;
  margin-left: 45px;
  font-size: 13px;
  font-weight: 400;
  color: #9393b0;
}

.JobDraftPrice {
  &__rateInput {
    &__wrapper {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding: 16px;
      border-radius: 16px;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__input {
        overflow: hidden;
        width: 75%;

        & .Input__labelContainer {
          margin-inline: 50%;
          transform: translateX(-50%);
        }

        .Input__input {
          text-align: center;
        }

        .Input__labelContainer.fullContainer {
          inset-inline-start: 0px;

          .Input__labelContainer__label {
            inset-inline-start: unset;
            position: unset;
          }
        }

        &_error {
          .Input__input {
            border-color: $error;
          }
          .Input__labelContainer {
            &__label {
              color: $error;
            }
          }
        }
      }

      &__button {
        width: 56px;
        height: 56px;
        border-radius: 8px;
        border: 1px solid $lighterGrey;
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30%;
        background-color: #fff;
        &_error {
          border-color: $error;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__labels {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 16px;
      font-weight: 400;

      &--green {
        color: #5eaa6f;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }

  &__alert {
    padding: 8px;
    display: flex;
    border-radius: 8px;
    background: rgba(119, 103, 228, 0.2);
    align-items: flex-start;
    margin-top: 24px;

    & > img {
      margin-top: 2px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      color: #323246;
      margin-left: 4px;
    }
  }

  &__totalContainer {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  &__total {
    &__title {
      color: #9e9eb8;
      font-size: 16px;
      font-weight: 600;
    }

    &__value {
      color: #323246;
      font-size: 16px;
      font-weight: 600;
    }

    &__note {
      margin-top: 5px;
      color: #9e9eb8;
      font-size: 11px;
      font-weight: 400;
    }
  }
}
